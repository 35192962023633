import React from 'react';
import About from '../About/About';
import Footer from '../Footer/Footer';
import Music from '../Music/Music';
import './App.css';
import IAppProps from './IAppProps';

class App extends React.Component<IAppProps, {}> {
  render() {
    return (
      <div className="App">
        <div className="App-center">
          <header>
            <h1 className="App-header">
              NORTHERN COLD
            </h1>
          </header>
          <div className="App-body">
            <About />
            <Music />
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

export default App;
