import React from 'react';
import './Footer.css';

class Footer extends React.Component<{}, {}> {
  render() {
    let now = new Date();
    return (
      <div className="Footer-Container">
        <p>Copyright {now.getFullYear()} Northern Cold</p>
      </div>
    );
  }
}

export default Footer;
