import React from 'react';
import ISectionHeaderProps from './ISectionHeaderProps';
import './SectionHeader.css';

class SectionHeader extends React.Component<ISectionHeaderProps, {}> {
  render() {
    return (
      <div className="SectionHeader">
        <h3>{this.props.sectionName}</h3>
      </div>
    );
  }
}

export default SectionHeader;
