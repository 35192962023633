import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './About.css';

class About extends React.Component {
  render() {
    return (
      <div className="About">
        <Container fluid>
          <Row>
            <Col md={6} className="About-Text-Container">
              <h3 className="About-Header">ABOUT</h3>
              <div className="About-Text">
                <span className="About-Text-Italic">NORTHERN COLD</span> is a rising electronic music producer from Seattle, USA. Independently releasing tracks and DJ mix sets has allowed him to grow a fanbase at the intersection of hard techno and trance. 
                <p></p>
                  His signature sound is influenced by hypnotic rave music from the 90s and early 00s, as well as elements from hardcore punk and doom metal. The result is a unique new sound that breaks through traditional genre boundaries and heats up the dance floor.
                <p></p>
                  Mailing list signup, merch store, Spotify playlists, and more links can be found on <a href="https://linktr.ee/northerncold" target="_blank">LinkTree</a>.
              </div>
              <div className="Social-Row">
                <div className="Follow-Text">Social Media: </div>
                <div className="Social-Col">
                  <a href="https://www.tiktok.com/@northerncold.music" target="_blank" rel="noreferrer">
                    <img
                      width={35}
                      height={35}
                      src="assets\icons8-tiktok-sm-white.png"
                      className="Social-Icon"
                      alt="TikTok icon" />
                  </a>
                </div>
                <div className="Social-Col">
                  <a href="https://www.instagram.com/northerncold.music" target="_blank" rel="noreferrer">
                    <img
                      width={35}
                      height={35}
                      src="assets\sm-icons-instagram-glyph-logo-white.png"
                      className="Social-Icon"
                      alt="Instagram icon" />
                  </a>
                </div>
                <div className="Social-Col">
                  <a href="https://www.facebook.com/northerncoldmusic" target="_blank" rel="noreferrer">
                    <img
                      width={35}
                      height={35}
                      src="assets\icons-facebook-logo-white.png"
                      className="Social-Icon"
                      alt="Facebook icon" />
                  </a>
                </div>
                <div className="Social-Col">
                  <a href="https://www.twitch.tv/northerncoldmusic" target="_blank" rel="noreferrer">
                    <img
                      height={35}
                      src="assets\twitch_white_120px.png"
                      className="Social-Icon"
                      alt="Twitch icon" />
                  </a>
                </div>
              </div>
              <div className="Social-Row">
                <div className="Follow-Text">Contact: </div>
                <div className="Social-Col">
                  <a href="mailto:keith@northerncoldmusic.com"><i className="ms-Icon ms-Icon--NewMail Email-Icon"></i></a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <Row>
                <Col>
                  <div className="About-Image-Container">
                    <img
                      width={300}
                      height={300}
                      className="About-Image"
                      src="artist-bio-v3.png"
                      alt="artist"
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="Social-Row-Container">
            
          </Row>
        </Container>
      </div>
    );
  }
}

export default About;
