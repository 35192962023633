import React from 'react';
import './Platforms.css';

class Platforms extends React.Component {
  
  platformInfo = [
    {
      "ID": 1,
      "Name": "Bandcamp",
      "Url": "https://northerncold.bandcamp.com/releases",
      "Image": "/assets/bandcamp_white_200x40.png"
    },
    {
      "ID": 2,
      "Name": "Spotify",
      "Url": "https://open.spotify.com/artist/3wXBnwdDE8CfeDtSvO4lrh",
      "Image": "/assets/spotify_white_200x40.png"
    },
    {
      "ID": 3,
      "Name": "Apple Music",
      "Url": "https://music.apple.com/us/artist/northern-cold/1550007772",
      "Image": "/assets/apple_music_white_200x40.png"
    },
    {
      "ID": 4,
      "Name": "YouTube",
      "Url": "https://www.youtube.com/channel/UCIZA-V_aUijgivQAFnyjiFQ/videos",
      "Image": "/assets/youtube-200x40_white_v2.png"
    },
    {
      "ID": 5,
      "Name": "Deezer",
      "Url": "https://www.deezer.com/us/artist/130375502",
      "Image": "/assets/deezer_white_200x40.png"
    },
    {
      "ID": 6,
      "Name": "Soundcloud",
      "Url": "https://soundcloud.com/northerncold",
      "Image": "/assets/soundcloud_white_200x40.png"
    },
    {
      "ID": 7,
      "Name": "Tidal",
      "Url": "https://tidal.com/browse/artist/23158012",
      "Image": "/assets/tidal_white_200x40.png"
    },
    {
      "ID": 8,
      "Name": "Pandora",
      "Url": "https://www.pandora.com/artist/northern-cold/ARJhP2VfkPff3ZK",
      "Image": "/assets/pandora_white_200x40.png"
    }
  ];

  render() {
    let platformRows = [];

    for (let i = 0; i < this.platformInfo.length; i++) {
      let item = this.platformInfo[i] as any;

      platformRows.push(
        <a href={item.Url} target="_blank" rel="noreferrer" key={item.ID}>
          <img className='Platform-Logo'
            src={item.Image}
            alt={item.Name}
          />
        </a>
      );
    }

    return (
      <div className="Platforms">
        <h6 className="Platforms-Header">Platforms</h6>
        <hr className="Platforms-Section-Line" />
        <div className='Platforms-Contents'>
          {platformRows}
        </div>
      </div>
    );
  }
}

export default Platforms;
