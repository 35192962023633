import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Platforms from '../Platforms/Platforms';
import SectionHeader from '../SectionHeader/SectionHeader';
import './Music.css';

class Music extends React.Component {
  render() {
    return (
      <div className="Music">
        <SectionHeader sectionName="MUSIC" />
        <Container fluid>
          <Row>
            <Col md={12}><Platforms /></Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Music;
